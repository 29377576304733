/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */
const { loadableReady } = require('@loadable/component');

exports.replaceHydrateFunction = () => {
  return (element, container, callback) => {
    loadableReady(() => {
      // eslint-disable-next-line no-undef
      ReactDOM.hydrate(element, container, callback);
    });
  };
};
